.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
  background-image: url('/src/Components/Images/succulent.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
